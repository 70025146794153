














































import { Component, Mixins, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XDataTable from "@/components/hoc/Table.vue";
import XSlider from "@/components/SimpleSlider.vue";
import AccountingPointPlanCard from "@/components/for-monthly-plan-view/AccountingPointPlanCard.vue";
import { mapGetters } from "vuex";
import { valueOrDash } from "@/assets/scripts/utils";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import { AccountingPointsByObject } from "@/models/accounting-point";

interface AccountingPointLocal {
  deviceId: number;
  deviceType: string;
  deviceAddress: string;
  serviceType: string;
}

@Component({
  components: {
    Page,
    XDataTable,
    XSlider,
    AccountingPointPlanCard,
  },
  computed: {
    ...mapGetters({
      sortedPointsByObject: "accountingPoints/sortedPointsByObject",
      pointNumber: "accountingPoint/number",
      pointName: "accountingPoint/name",
    }),
  },
})
class MonthlyPlan extends Mixins(AppApiMixin) {
  [x: string]: any;

  sortedPointsByObject!: AccountingPointsByObject[];

  get transformedPointsByObject() {
    const { sortedPointsByObject } = this;

    if (!sortedPointsByObject) return [];

    return sortedPointsByObject.map((point: any) => {
      return {
        ...point,
        pointList: point.pointList.map((item: any) => [
          valueOrDash(`${item.номер}`),
          valueOrDash(item.названиету),
          valueOrDash(item.адрес),
        ]),
      };
    });
  }

  headers = [
    {
      text: "Номер",
      value: "deviceId",
      width: "10%",
    },
    {
      text: "Наименование",
      value: "deviceType",
      width: "40%",
    },
    { text: "Адрес", value: "deviceAddress", width: "50%" },
    // { text: "Услуга", value: "serviceType", width: "10%" },
  ];

  showPlanByAccountingPoint = false;
  chosenPointId = 0;
  address = "";

  public openPlanByAccountingPoint(point: AccountingPointLocal) {
    this.chosenPointId = point.deviceId;
    this.address = point.deviceAddress;
    this.showPlanByAccountingPoint = true;
  }

  public closePlanByAccountingPoint() {
    this.showPlanByAccountingPoint = false;
    this.chosenPointId = 0;
  }

  public log(message: any) {
    console.log(message);
  }
}

export default MonthlyPlan;
